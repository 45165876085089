html,
body,
#root,
.app-wrapper {
  height: 100%;
  width: 100%;
  min-width: 1024px;
  position: absolute;
}

body {
  background-color: #ffffff;
}
